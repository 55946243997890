import {
  EuiBasicTable,
  EuiBasicTableProps,
  EuiSkeletonText,
} from "@inscopix/ideas-eui";
import { CallOutError } from "components/CallOutError/CallOutError";
import { Task, useGetTaskResourceUsageByTaskIdQuery } from "graphql/_Types";
import { isNullish } from "utils/isNullish";
import { roundToSignificant } from "utils/roundToSignificant";

type Field = "min" | "max" | "mean" | "median" | "std";
type TableItem = { [x in Field]: number | null } & { resource: string };

export interface ModalTaskLogsResourceUsageProps {
  task: Pick<Task, "id" | "status" | "created">;
}

export const ModalTaskLogsResourceUsage = ({
  task,
}: ModalTaskLogsResourceUsageProps) => {
  const { loading, error, data } = useGetTaskResourceUsageByTaskIdQuery({
    variables: {
      id: task.id,
    },
    fetchPolicy: "network-only",
  });

  const resourceUsage = data?.task?.resourceUsage;

  if (loading) {
    return <EuiSkeletonText lines={3} />;
  }
  if (error || isNullish(resourceUsage)) {
    return <CallOutError>Failed to fetch task resource usage</CallOutError>;
  }

  const formatNumberOrNull = (val: number | null) =>
    val !== null ? roundToSignificant(val) : "ND";

  const columns: (EuiBasicTableProps<TableItem>["columns"][number] & {
    field: Field | "resource";
  })[] = [
    {
      field: "resource",
      name: "Resource",
      width: "150px",
    },
    {
      field: "min",
      name: "Minimum",
      render: formatNumberOrNull,
      width: "90px",
    },
    {
      field: "max",
      name: "Maximum",
      render: formatNumberOrNull,
      width: "90px",
    },
    {
      field: "mean",
      name: "Mean",
      render: formatNumberOrNull,
      width: "90px",
    },
    {
      field: "median",
      name: "Median",
      render: formatNumberOrNull,
      width: "90px",
    },
    {
      field: "std",
      name: "Standard Deviation",
      render: formatNumberOrNull,
    },
  ];

  const items: EuiBasicTableProps<TableItem>["items"] = [
    {
      resource: "Cpu (%)",
      min: resourceUsage.cpuMin,
      max: resourceUsage.cpuMax,
      mean: resourceUsage.cpuMean,
      median: resourceUsage.cpuMedian,
      std: resourceUsage.cpuStd,
    },
    {
      resource: "Memory (Mb)",
      min: resourceUsage.memoryMin,
      max: resourceUsage.memoryMax,
      mean: resourceUsage.memoryMean,
      median: resourceUsage.memoryMedian,
      std: resourceUsage.memoryStd,
    },
    {
      resource: "Storage (Mb)",
      min: resourceUsage.storageMin,
      max: resourceUsage.storageMax,
      mean: resourceUsage.storageMean,
      median: resourceUsage.storageMedian,
      std: resourceUsage.storageStd,
    },
  ];

  return <EuiBasicTable columns={columns} items={items} />;
};
