import { EuiResizableContainer, EuiSpacer } from "@inscopix/ideas-eui";
import { Fragment, memo, useCallback, useEffect, useState } from "react";
import { FlyoutProjectFiles } from "./FlyoutProjectFiles";
import { ProjectDescription } from "./ProjectDescription";
import { useProjectLayoutContext } from "./ProjectLayoutProvider";
import { ProjectPanels } from "./ProjectPanels";

const ProjectInfo = memo(function ProjectInfo() {
  return (
    <Fragment>
      <ProjectDescription />
      <EuiSpacer size="s" />
      <ProjectPanels />
    </Fragment>
  );
});

enum PanelId {
  "INFO_AND_FLYOUT_BOTTOM" = "INFO_AND_FLYOUT_BOTTOM",
  "INFO" = "INFO",
  "FLYOUT_BOTTOM" = "FLYOUT_BOTTOM",
  "FLYOUT_RIGHT" = "FLYOUT_RIGHT",
}

interface ProjectContentProps {
  isFileBrowserActive: boolean;
  onCloseFileBrowser: () => void;
}

/**
 * Component that renders the main content of a project. This includes the
 * project description, dataset panel, analysis table panel and all flyouts.
 */
export const ProjectContent = ({
  isFileBrowserActive,
  onCloseFileBrowser,
}: ProjectContentProps) => {
  const { rightFlyout } = useProjectLayoutContext();
  const isRightFlyoutOpen = rightFlyout !== null;
  const [panelSizes, setPanelSizes] = useState<Record<PanelId, number>>({
    [PanelId.INFO_AND_FLYOUT_BOTTOM]: 100,
    [PanelId.INFO]: 100,
    [PanelId.FLYOUT_BOTTOM]: 0,
    [PanelId.FLYOUT_RIGHT]: 0,
  });

  /**
   * Updates panel size state after panel widths change.
   * @param newPanelSizes
   */
  const handlePanelWidthChange = useCallback(
    (newPanelSizes: Partial<typeof panelSizes>) => {
      setPanelSizes((prevPanelSizes) => ({
        ...prevPanelSizes,
        ...newPanelSizes,
      }));
    },
    [],
  );

  // Sets the size of each panel affected by the bottom flyout opening or closing
  useEffect(() => {
    if (isFileBrowserActive) {
      setPanelSizes((prevPanelSizes) => ({
        ...prevPanelSizes,
        [PanelId.INFO]: 50,
        [PanelId.FLYOUT_BOTTOM]: 50,
      }));
    } else {
      setPanelSizes((prevPanelSizes) => ({
        ...prevPanelSizes,
        [PanelId.INFO]: 100,
        [PanelId.FLYOUT_BOTTOM]: 0,
      }));
    }
  }, [isFileBrowserActive]);

  // Sets the size of each panel affected by the right flyout opening or closing
  useEffect(() => {
    if (isRightFlyoutOpen) {
      setPanelSizes((prevPanelSizes) => ({
        ...prevPanelSizes,
        [PanelId.INFO_AND_FLYOUT_BOTTOM]: 70,
        [PanelId.FLYOUT_RIGHT]: 30,
      }));
    } else {
      setPanelSizes((prevPanelSizes) => ({
        ...prevPanelSizes,
        [PanelId.INFO_AND_FLYOUT_BOTTOM]: 100,
        [PanelId.FLYOUT_RIGHT]: 0,
      }));
    }
  }, [isRightFlyoutOpen]);

  return (
    <EuiResizableContainer
      direction="horizontal"
      onPanelWidthChange={handlePanelWidthChange}
      style={{ width: "100%", height: "100%" }}
    >
      {(EuiResizablePanel, EuiResizableButton) => (
        <>
          <EuiResizablePanel
            id={PanelId.INFO_AND_FLYOUT_BOTTOM}
            size={panelSizes[PanelId.INFO_AND_FLYOUT_BOTTOM]}
            paddingSize="none"
            minSize="0px"
            // minSize is not preserved when window is resized
            wrapperProps={{ style: { minWidth: "0px" } }}
          >
            <EuiResizableContainer
              direction="vertical"
              onPanelWidthChange={handlePanelWidthChange}
              style={{ width: "100%", height: "100%" }}
            >
              {(EuiResizablePanel, EuiResizableButton) => (
                <>
                  <EuiResizablePanel
                    id={PanelId.INFO}
                    size={panelSizes[PanelId.INFO]}
                    paddingSize="none"
                    minSize="0px"
                    // minSize is not preserved when window is resized
                    wrapperProps={{ style: { minHeight: "0px" } }}
                  >
                    <ProjectInfo />
                  </EuiResizablePanel>

                  <EuiResizableButton
                    indicator="border"
                    style={{
                      display: isFileBrowserActive ? undefined : "none",
                    }}
                  />

                  <EuiResizablePanel
                    id={PanelId.FLYOUT_BOTTOM}
                    size={panelSizes[PanelId.FLYOUT_BOTTOM]}
                    paddingSize="none"
                    minSize="300px"
                    // minSize is not preserved when window is resized
                    wrapperProps={{
                      style: {
                        minHeight: isFileBrowserActive ? "300px" : undefined,
                      },
                    }}
                  >
                    <FlyoutProjectFiles onClose={onCloseFileBrowser} />
                  </EuiResizablePanel>
                </>
              )}
            </EuiResizableContainer>
          </EuiResizablePanel>

          <EuiResizableButton
            indicator="border"
            style={{ display: isRightFlyoutOpen ? undefined : "none" }}
          />

          <EuiResizablePanel
            id={PanelId.FLYOUT_RIGHT}
            size={panelSizes[PanelId.FLYOUT_RIGHT]}
            paddingSize="none"
            minSize="300px"
            // minSize is not preserved when window is resized
            wrapperProps={{
              style: {
                minWidth: isRightFlyoutOpen ? "300px" : undefined,
              },
            }}
          >
            {rightFlyout?.node}
          </EuiResizablePanel>
        </>
      )}
    </EuiResizableContainer>
  );
};
