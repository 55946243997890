import { EuiResizableContainer } from "@inscopix/ideas-eui";
import { RecordingsGrid } from "components/RecordingsGrid/RecordingsGrid";
import { useDatasetLayoutContext } from "pages/project/dataset/DatasetLayoutProvider";
import { useCallback, useEffect, useState } from "react";

enum PanelId {
  "TABLE_AND_FLYOUT_BOTTOM" = "TABLE_AND_FLYOUT_BOTTOM",
  "TABLE" = "TABLE",
  "FLYOUT_BOTTOM" = "FLYOUT_BOTTOM",
  "FLYOUT_RIGHT" = "FLYOUT_RIGHT",
}

/**
 * Component that renders the main content of a dataset. This includes the
 * recordings table and all flyouts.
 */
export const DatasetContent = () => {
  const { bottomFlyout, rightFlyout } = useDatasetLayoutContext();
  const isBottomFlyoutOpen = bottomFlyout !== null;
  const isRightFlyoutOpen = rightFlyout !== null;
  const [panelSizes, setPanelSizes] = useState<Record<PanelId, number>>({
    [PanelId.TABLE_AND_FLYOUT_BOTTOM]: 100,
    [PanelId.TABLE]: 100,
    [PanelId.FLYOUT_BOTTOM]: 0,
    [PanelId.FLYOUT_RIGHT]: 0,
  });

  /**
   * Updates panel size state after panel widths change.
   * @param newPanelSizes
   */
  const handlePanelWidthChange = useCallback(
    (newPanelSizes: Partial<typeof panelSizes>) => {
      setPanelSizes((prevPanelSizes) => ({
        ...prevPanelSizes,
        ...newPanelSizes,
      }));
    },
    [],
  );

  // Sets the size of each panel affected by the bottom flyout opening or closing
  useEffect(() => {
    if (isBottomFlyoutOpen) {
      setPanelSizes((prevPanelSizes) => ({
        ...prevPanelSizes,
        [PanelId.TABLE]: 50,
        [PanelId.FLYOUT_BOTTOM]: 50,
      }));
    } else {
      setPanelSizes((prevPanelSizes) => ({
        ...prevPanelSizes,
        [PanelId.TABLE]: 100,
        [PanelId.FLYOUT_BOTTOM]: 0,
      }));
    }
  }, [isBottomFlyoutOpen]);

  // Sets the size of each panel affected by the right flyout opening or closing
  useEffect(() => {
    if (isRightFlyoutOpen) {
      setPanelSizes((prevPanelSizes) => ({
        ...prevPanelSizes,
        [PanelId.TABLE_AND_FLYOUT_BOTTOM]: 70,
        [PanelId.FLYOUT_RIGHT]: 30,
      }));
    } else {
      setPanelSizes((prevPanelSizes) => ({
        ...prevPanelSizes,
        [PanelId.TABLE_AND_FLYOUT_BOTTOM]: 100,
        [PanelId.FLYOUT_RIGHT]: 0,
      }));
    }
  }, [isRightFlyoutOpen]);

  return (
    <EuiResizableContainer
      direction="horizontal"
      onPanelWidthChange={handlePanelWidthChange}
      style={{ width: "100%", height: "100%" }}
    >
      {(EuiResizablePanel, EuiResizableButton) => (
        <>
          <EuiResizablePanel
            id={PanelId.TABLE_AND_FLYOUT_BOTTOM}
            size={panelSizes[PanelId.TABLE_AND_FLYOUT_BOTTOM]}
            paddingSize="none"
            minSize="0px"
            // minSize is not preserved when window is resized
            wrapperProps={{ style: { minWidth: "0px" } }}
          >
            <EuiResizableContainer
              direction="vertical"
              onPanelWidthChange={handlePanelWidthChange}
              style={{ width: "100%", height: "100%" }}
            >
              {(EuiResizablePanel, EuiResizableButton) => (
                <>
                  <EuiResizablePanel
                    id={PanelId.TABLE}
                    size={panelSizes[PanelId.TABLE]}
                    paddingSize="none"
                    minSize="0px"
                    // minSize is not preserved when window is resized
                    wrapperProps={{ style: { minHeight: "0px" } }}
                  >
                    <RecordingsGrid />
                  </EuiResizablePanel>

                  <EuiResizableButton
                    indicator="border"
                    style={{ display: isBottomFlyoutOpen ? undefined : "none" }}
                  />

                  <EuiResizablePanel
                    id={PanelId.FLYOUT_BOTTOM}
                    size={panelSizes[PanelId.FLYOUT_BOTTOM]}
                    paddingSize="none"
                    minSize="300px"
                    // minSize is not preserved when window is resized
                    wrapperProps={{
                      style: {
                        minHeight: isBottomFlyoutOpen ? "300px" : undefined,
                      },
                    }}
                  >
                    {bottomFlyout?.node}
                  </EuiResizablePanel>
                </>
              )}
            </EuiResizableContainer>
          </EuiResizablePanel>

          <EuiResizableButton
            indicator="border"
            style={{ display: isRightFlyoutOpen ? undefined : "none" }}
          />

          <EuiResizablePanel
            id={PanelId.FLYOUT_RIGHT}
            size={panelSizes[PanelId.FLYOUT_RIGHT]}
            paddingSize="none"
            minSize="300px"
            // minSize is not preserved when window is resized
            wrapperProps={{
              style: {
                minWidth: isRightFlyoutOpen ? "300px" : undefined,
              },
            }}
          >
            {rightFlyout?.node}
          </EuiResizablePanel>
        </>
      )}
    </EuiResizableContainer>
  );
};
