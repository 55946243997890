import { EuiResizableContainer } from "@inscopix/ideas-eui";
import { ToolParamsGrid } from "components/ToolParamsGrid/ToolParamsGrid";
import { useAnalysisTableLayoutContext } from "pages/project/analysis/AnalysisTableLayoutProvider";
import { useCallback, useEffect, useState } from "react";

enum PanelId {
  "TABLE" = "TABLE",
  "FLYOUT_RIGHT" = "FLYOUT_RIGHT",
}

/**
 * Component that renders the main content of an analysis table. This includes
 * the analysis table and all flyouts.
 */
export const AnalysisTableContent = () => {
  const { rightFlyout } = useAnalysisTableLayoutContext();
  const isRightFlyoutOpen = rightFlyout !== null;
  const [panelSizes, setPanelSizes] = useState<Record<PanelId, number>>({
    [PanelId.TABLE]: 100,
    [PanelId.FLYOUT_RIGHT]: 0,
  });

  /**
   * Updates panel size state after panel widths change.
   * @param newPanelSizes
   */
  const handlePanelWidthChange = useCallback(
    (newPanelSizes: Partial<typeof panelSizes>) => {
      setPanelSizes((prevPanelSizes) => ({
        ...prevPanelSizes,
        ...newPanelSizes,
      }));
    },
    [],
  );

  // Sets the size of each panel affected by the right flyout opening or closing
  useEffect(() => {
    if (isRightFlyoutOpen) {
      setPanelSizes((prevPanelSizes) => ({
        ...prevPanelSizes,
        [PanelId.TABLE]: 70,
        [PanelId.FLYOUT_RIGHT]: 30,
      }));
    } else {
      setPanelSizes((prevPanelSizes) => ({
        ...prevPanelSizes,
        [PanelId.TABLE]: 100,
        [PanelId.FLYOUT_RIGHT]: 0,
      }));
    }
  }, [isRightFlyoutOpen]);

  return (
    <EuiResizableContainer
      direction="horizontal"
      onPanelWidthChange={handlePanelWidthChange}
      style={{ width: "100%", height: "100%" }}
    >
      {(EuiResizablePanel, EuiResizableButton) => (
        <>
          <EuiResizablePanel
            id={PanelId.TABLE}
            size={panelSizes[PanelId.TABLE]}
            paddingSize="none"
            minSize="0px"
            // minSize is not preserved when window is resized
            wrapperProps={{ style: { minWidth: "0px" } }}
          >
            <ToolParamsGrid />
          </EuiResizablePanel>

          <EuiResizableButton
            indicator="border"
            style={{ display: isRightFlyoutOpen ? undefined : "none" }}
          />

          <EuiResizablePanel
            id={PanelId.FLYOUT_RIGHT}
            size={panelSizes[PanelId.FLYOUT_RIGHT]}
            paddingSize="none"
            minSize="300px"
            // minSize is not preserved when window is resized
            wrapperProps={{
              style: {
                minWidth: isRightFlyoutOpen ? "300px" : undefined,
              },
            }}
          >
            {rightFlyout?.node}
          </EuiResizablePanel>
        </>
      )}
    </EuiResizableContainer>
  );
};
