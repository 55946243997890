/** @jsxImportSource @emotion/react */
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
} from "@inscopix/ideas-eui";
import {
  AnalysisTable as IAnalysisTable,
  AnalysisTableGroup,
  Project,
  Tool,
  ToolVersion,
} from "graphql/_Types";
import { css } from "@emotion/react";
import { useMemo, useState } from "react";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { ModalEditAnalysisTableGroup } from "./ModalEditAnalysisTableGroup";
import { useAnalysisTableLayoutContext } from "pages/project/analysis/AnalysisTableLayoutProvider";
import { AnalysisTableMenuBar } from "./AnalysisTableMenuBar";
import { useToolSpecContext } from "providers/ToolSpecProvider/ToolSpecProvider";
import { chain } from "lodash";
import { compare } from "semver";
import { AnalysisTableButtonCreateTab } from "./AnalysisTableButtonCreateTab";
import { AnalysisTableButtonTab } from "./AnalysisTableButtonTab";
import { AnalysisTableBottomBar } from "./AnalysisTableBottomBar";
import { AnalysisTableHeader } from "./AnalysisTableHeader";
import { AnalysisTableContent } from "./AnalysisTableContent";

export interface AnalysisTableProps {
  tool: Pick<Tool, "id" | "key">;
  group: {
    id: AnalysisTableGroup["id"];
    name: string;
  };
  tables: {
    id: IAnalysisTable["id"];
    name: string;
    toolVersions: {
      version: ToolVersion["version"];
      maturity: ToolVersion["maturity"];
      credits: ToolVersion["credits"];
    }[];
    totalCredits: number;
    projectId: Project["id"];
    userId: IAnalysisTable["userId"];
    tenantId: IAnalysisTable["tenantId"];
  }[];
  selectedTableId: string | undefined;
  setSelectedTableId: (tableId: string) => Promise<void>;
}

export const AnalysisTable = ({
  tool,
  group,
  tables,
  selectedTableId,
  setSelectedTableId,
}: AnalysisTableProps) => {
  const { modal } = useAnalysisTableLayoutContext();
  const [showEditModal, setShowEditModal] = useState(false);
  const { availableToolSpecs } = useToolSpecContext();

  const selectedTable = tables.find((table) => table.id === selectedTableId);

  const latestToolSpec = useMemo(() => {
    return chain(availableToolSpecs)
      .filter((spec) => spec.toolId === tool.id)
      .sort((a, b) => compare(a.spec.version, b.spec.version))
      .last()
      .value();
  }, [availableToolSpecs, tool.id]);

  const newestToolVersion = useMemo(() => {
    const isNewToolVersion = !tables.some((table) =>
      table.toolVersions.some(
        (version) => latestToolSpec.spec.version === version.version,
      ),
    );

    return isNewToolVersion ? latestToolSpec : undefined;
  }, [latestToolSpec, tables]);

  return (
    <>
      <EuiFlexGroup
        direction="column"
        gutterSize="none"
        css={css`
          height: 100%;
          min-width: 650px;
        `}
      >
        <EuiFlexItem grow={false}>
          <AnalysisTableHeader
            group={group}
            tables={tables}
            tool={tool}
            setShowEditModal={setShowEditModal}
            latestToolSpec={latestToolSpec}
          />
        </EuiFlexItem>
        {selectedTable && (
          <EuiFlexItem grow={false}>
            <AnalysisTableMenuBar group={group} table={selectedTable} />
          </EuiFlexItem>
        )}
        <EuiHorizontalRule margin="none" />
        <EuiFlexItem grow={false}>
          <EuiFlexGroup
            style={{ backgroundColor: "#f5f7f7", padding: 5 }}
            gutterSize="s"
            responsive={false}
          >
            {newestToolVersion && selectedTable && (
              <EuiFlexItem grow={false}>
                <AnalysisTableButtonCreateTab
                  analysisTableGroupId={group.id}
                  projectId={selectedTable.projectId}
                  toolVersionId={newestToolVersion.toolVersionId}
                  setSelectedTableId={setSelectedTableId}
                />
              </EuiFlexItem>
            )}

            {tables.map((table) => {
              return (
                <EuiFlexItem key={table.id} grow={false}>
                  <AnalysisTableButtonTab
                    isSelected={selectedTableId === table.id}
                    table={table}
                    setSelectedTableId={setSelectedTableId}
                  />
                </EuiFlexItem>
              );
            })}
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem style={{ minHeight: 0 }}>
          <AnalysisTableContent />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <AnalysisTableBottomBar selectedTableId={selectedTableId} />
        </EuiFlexItem>
      </EuiFlexGroup>

      {modal}
      {showEditModal && (
        <ModalEditAnalysisTableGroup
          onClose={() => setShowEditModal(false)}
          onError={() => addUtilityToastFailure("Failed to edit table")}
          onSuccess={() => addUtilityToastSuccess("Successfully edited table")}
          analysisTableGroup={group}
        />
      )}
    </>
  );
};
